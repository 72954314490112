<template>
	<div class="details">
		<Selectshop
		 :proData="transmitData"
		  />
		<div class="conten_body">  
			<div class="conten_left">
				<ul class="nav_list">
					<li><a class="a_active" href="">产品介绍</a></li>
					<li><a href="">服务流程</a></li>
					<li><a href="">服务优势</a></li>
					<li><a href="">常见问题</a></li>
				</ul>
				
            
        <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
				<!-- <img class="" src="@/assets/images/yunsafe/01.jpg"> -->
					<img class="" src="@/assets/images/yunsafe/02.jpg">
					<!-- <img class="" src="@/assets/images/yunsafe/03.jpg"> -->
					<img class="" src="@/assets/images/yunsafe/04.jpg">
					
				</div>
				
			</div>
			<!-- right -->
			<Hotgoods/>
			
		</div>
	</div>
</template>

<script type="text/javascript">
import Hotgoods from "@/components/hotgoods.vue"
import Selectshop from "@/components/Selectshop.vue"

export default{
components:{
      Hotgoods,
      Selectshop
	},
data(){
	return{
		transmitData:{
			title:"云上业务安全无忧保障计划中级套餐",
			content:"",
			price:'在线咨询',
			url:require('../../assets/images/yunsafe/222.jpg')
		}
	}
},
mounted(){

},
methods:{
	
}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}




}


</style>







